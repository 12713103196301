export const getImageId = (bundleMediaFiles, mediaFileType) =>
  bundleMediaFiles.find((mediaFile) => mediaFile.type === mediaFileType)?.id;

// eslint-disable-next-line no-unused-vars
const addressWithoutDeliveryType = ({ deliveryType, ...deliveryAddress }) =>
  deliveryAddress;

export const buildLoanApplicationData = (formData, bundleId, storeCode) => {
  return {
    bundleId: bundleId,
    ...formData,
    personalIdCountryCode: 'NO',
    deliveryAddress: {
      ...formData.deliveryAddress,
      deliveryType: 'CUSTOMER_ADDRESS',
      countryCode: 'NO',
    },
    invoiceAddress: {
      ...addressWithoutDeliveryType(formData.deliveryAddress),
      countryCode: 'NO',
    },
    deliveryAddressSameAsInvoice: true,
    storeCode: storeCode,
  };
};

export const getDescriptionWithBullets = (description) => {
  return description?.split(';').map((value, index) => {
    return index === 0 ? value : `\u2022 ${value.trim()}`;
  });
};
