export const RESET_STORE = 'RESET_STORE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const IS_LOADING = 'IS_LOADING';
export const HAS_ERROR = 'HAS_ERROR';
export const NOTIFICATION = 'NOTIFICATION';

export const SET_COOKIE_CONSENT = 'SET_COOKIE_CONSENT';
export const HANDLE_COOKIE_CONSENT = 'HANDLE_COOKIE_CONSENT';

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';

export const GET_STORES = 'GET_STORES';
export const SET_STORES = 'SET_STORES';

export const GET_BUNDLES = 'GET_BUNDLES';
export const SET_BUNDLES = 'SET_BUNDLES';

export const SET_SELECTED_BUNDLE = 'SET_SELECTED_BUNDLE';

export const ASK_QUESTION = 'ASK_QUESTION';

export const GET_LOGIN_INFO = 'GET_LOGIN_INFO';
export const SET_LOGIN_INFO = 'SET_LOGIN_INFO';

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';

export const GET_INDIVIDUALIZED_TERMS = 'GET_INDIVIDUALIZED_TERMS';
export const SIGN_WITH_BANK_ID = 'SIGN_WITH_BANK_ID';

export const SET_LOAN_APPLICATION_DATA = 'SET_LOAN_APPLICATION_DATA';
export const SUBMIT_LOAN_APPLICATION = 'SUBMIT_LOAN_APPLICATION';
export const HAS_LOAN_APPLICATION_ERROR = 'HAS_LOAN_APPLICATION_ERROR';
export const SET_LOAN_APPLICATION_DECISION = 'SET_LOAN_APPLICATION_DECISION';
export const GET_APPLICATION_STATUS = 'GET_APPLICATION_STATUS';
export const SET_APPLICATION_STATUS = 'SET_APPLICATION_STATUS';
export const POLL_PAYMENT_RESPONSE = 'POLL_PAYMENT_RESPONSE';
export const HANDLE_PAYMENT_RESPONSE = 'HANDLE_PAYMENT_RESPONSE';
export const SET_LOAN_REFERENCE_NUMBER = 'SET_LOAN_REFERENCE_NUMBER';

export const SET_BUNDLE_SELECTED_STORE_CODE = 'SET_BUNDLE_SELECTED_STORE_CODE';

export const IS_BUNDLE_ORDER_COMPLETE = 'IS_BUNDLE_ORDER_COMPLETE';
export const SET_SIGNATURE_STATUS = 'SET_SIGNATURE_STATUS';

export const SET_GEOCODER_ERROR = 'SET_GEOCODER_ERROR';
export const DUMMY_LOGIN = 'DUMMY_LOGIN';
