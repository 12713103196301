import { API_BASE_URL } from '@/constants';
import HttpClient from './HttpClient';

export default {
  getStores() {
    return HttpClient.get({
      url: `${API_BASE_URL}/public/store/search`,
    });
  },
  getBundles() {
    return HttpClient.post({
      url: `${API_BASE_URL}/public/bundle/search`,
    });
  },
  askQuestion(data) {
    return HttpClient.post({
      url: `${API_BASE_URL}/public/app/customer-question`,
      data: data,
    });
  },
  submitLoanApplication(loanApplicationData) {
    return HttpClient.post({
      url: `${API_BASE_URL}/subscription`,
      data: loanApplicationData,
    });
  },
  submitPayment(referenceNumber, language) {
    return HttpClient.post({
      url: `${API_BASE_URL}/subscription/${referenceNumber}/resurs-merchant/v2/payment`,
      headers: {
        'Fairown-Language-Code': language,
      },
    });
  },
  pollPaymentResponse(referenceNumber) {
    return HttpClient.get({
      url: `${API_BASE_URL}/subscription/${referenceNumber}/payment/status`,
    });
  },
  getAuthInfo() {
    return HttpClient.get({
      url: '/public/auth-info',
    });
  },
  dummyLogin(data) {
    return HttpClient.post({
      url: '/client/public/dummy-login ',
      data: data,
    });
  },
};
