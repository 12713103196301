// This file is quite pointless without custom VeeValidate.extend methods
// but it is here for the setup for future changes
import validationMessagesEN from 'vee-validate/dist/locale/en';
import validationMessagesNO from 'vee-validate/dist/locale/nn_NO';

import { Validator } from 'vee-validate';
import { validatePersonalCode } from './utils/validations';

export const dictionary = {
  en: {
    ...validationMessagesEN,
    messages: {
      ...validationMessagesEN.messages,
    },
  },
  no: {
    ...validationMessagesNO,
    messages: {
      ...validationMessagesNO.messages,
    },
  },
};

Validator.extend('personalCode', (personalCode) =>
  validatePersonalCode(personalCode),
);
