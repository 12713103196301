<template>
  <div class="language-switcher">
    <Select
      name="languages"
      label="Select language"
      label-hidden
      v-model="$i18n.locale"
      :options="selectOptions"
      @change="setLanguage"
      size="extra-small"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { SET_LANGUAGE } from '@/types';
import { Select } from '@/components';
import { setDocumentTitle, setHtmlElementLanguage } from '@/utils';
import { constants } from '@/mixins';

export default {
  name: 'LanguageSwitcher',
  mixins: [constants],
  components: {
    Select,
  },
  methods: {
    ...mapActions([SET_LANGUAGE]),
    setLanguage(value) {
      const titleKey = this.$router.currentRoute.meta.titleKey;
      setDocumentTitle(
        titleKey ? this.$t(`ROUTER.${titleKey}`) : null,
        this.$t('ROUTER.DEFAULT_TITLE'),
      );
      setHtmlElementLanguage(value);
      this.SET_LANGUAGE(value);
    },
  },
  computed: {
    ...mapState(['language']),
    selectOptions() {
      return this.STORE.availableLocales.map((lang) => ({
        value: lang,
        label: this.$t(`LANGUAGES.${lang.toUpperCase()}`),
      }));
    },
  },
};
</script>

<style lang="scss">
.language-switcher {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
</style>
