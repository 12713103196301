<template>
  <footer class="footer">
    <Container>
      <Separator size="medium" hasLine />
      <Container>
        <b-row>
          <b-col>
            <b-col class="footer-heading">
              <h6>{{ $t('FOOTER.HEADING.COMPANY') }}</h6>
            </b-col>
            <b-col
              v-for="(item, index) in getFooterItemsByType('CompanyItem')"
              :key="index"
            >
              <a
                :href="item.url"
                class="footer-item text-color-black"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{{ $t(`FOOTER.ITEM.${item.label}`) }}</span>
              </a>
            </b-col>
          </b-col>
          <b-col>
            <b-col class="footer-heading">
              <h6>{{ $t('FOOTER.HEADING.SOCIAL_MEDIA') }}</h6>
            </b-col>
            <b-col
              v-for="(item, index) in getFooterItemsByType('SocialMediaItems')"
              :key="index"
            >
              <a
                :href="item.url"
                class="footer-item text-color-black"
                target="_blank"
                rel="noopener noreferrer"
              >
                <component :is="item.icon" />
                <span class="social-media-item">{{
                  $t(`FOOTER.ITEM.${item.label}`)
                }}</span>
              </a>
            </b-col>
          </b-col>
          <b-col>
            <b-col class="footer-heading">
              <h6>{{ $t('FOOTER.HEADING.CONTACT') }}</h6>
            </b-col>
            <b-col
              v-for="(item, index) in getFooterItemsByType('ContactItems')"
              :key="index"
            >
              <span class="text-uppercase footer-item">
                {{ $t(`FOOTER.ITEM.${item.label}`) }}
              </span>
              {{ getStreetName(item.value) }}
              <br />
              {{ getPostalCode(item.value) }}
            </b-col>
          </b-col>
          <b-col class="text-align-end">
            <a
              href="https://www.stihl.no/"
              class="text-color-black"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h6>{{ $t('FOOTER.HEADING.MORE_INFO') }}</h6>
            </a>
          </b-col>
        </b-row>
      </Container>
      <Separator size="medium" hasLine />
      <Container>
        <b-row>
          <b-col>
            <a
              :href="privacyConsent"
              class="text-color-black"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h6>
                {{ $t('FOOTER.HEADING.PRIVACY_CONSENT') }}
              </h6>
            </a>
          </b-col>
          <b-col>
            <a
              :href="generalTerms"
              class="text-color-black"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h6 class="text-center">
                {{ $t('FOOTER.HEADING.GENERAL_TERMS') }}
              </h6>
            </a>
          </b-col>
          <b-col class="text-align-end">
            <BLink @click="showCookieConsentSettings" class="text-color-black">
              <h6>
                {{ $t('FOOTER.HEADING.COOKIE_SETTINGS') }}
              </h6>
            </BLink>
          </b-col>
        </b-row>
      </Container>
    </Container>
    <Separator size="medium" />
  </footer>
</template>

<script>
import { Container, Separator, Button } from '@/components';
import {
  BIconFacebook,
  BIconInstagram,
  BIconYoutube,
  BIconLinkedin,
  BLink,
} from 'bootstrap-vue';
import { resetCookieConsent } from '@/utils/CookieUtil';
import { footerDataMap } from '@/constants';

export default {
  name: 'Footer',
  components: {
    Container,
    Separator,
    BIconFacebook,
    BIconInstagram,
    BIconYoutube,
    BIconLinkedin,
    BLink,
    Button,
  },
  methods: {
    showCookieConsentSettings() {
      resetCookieConsent();
      this.$emit('open-cookie-consent-modal');
    },
    getFooterItemsByType(footerType) {
      return footerDataMap.get(footerType);
    },
    getStreetName(address) {
      return address.split(',')[0];
    },
    getPostalCode(address) {
      return address.split(',')[1];
    },
  },
  computed: {
    privacyConsent() {
      return `${window.location.origin}/documents/STIHLPrivacyPolicy.pdf`;
    },
    generalTerms() {
      return `${window.location.origin}/documents/GeneralTerms.pdf`;
    },
  },
};
</script>

<style lang="scss">
.footer {
  background-color: $background-color;
}

.text-align-end {
  text-align: end;
}

.footer-heading {
  padding-bottom: 20px;
}

.text-color-black {
  color: $color-black;
}

.footer-item {
  font-size: 0.95em;
}

.social-media-item {
  padding-left: 8px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
