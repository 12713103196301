<template>
  <div class="loader">
    <Margins>
      <p
        :class="[
          'loader__text',
          'margins__double',
          { 'has-loading-dots': textLoadingIndicator },
        ]"
        v-if="text"
      >
        <span>{{ text }}</span>
        <span v-if="!textLoadingIndicator">...</span>
      </p>
      <LoaderSVG
        :class="['icon', 'loader__icon', { margins__double: !!text }]"
        v-if="!textLoadingIndicator"
      />
    </Margins>
  </div>
</template>

<script>
import LoaderSVG from '@/assets/images/loader.svg';
import { Margins } from './index';

export default {
  name: 'Loader',
  components: {
    Margins,
    LoaderSVG,
  },
  props: {
    text: String,
    textLoadingIndicator: Boolean,
  },
};
</script>

<style lang="scss">
.loader__icon {
  font-size: 4rem;
}

.loader__text {
  text-align: center;

  &.has-loading-dots {
    &::after {
      content: '.';
      animation: dots 1s steps(5, end) infinite;
    }
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: $color-black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 $color-black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 $color-black, 0.5em 0 0 $color-black;
  }
}
</style>
