<template>
  <div class="marketing-view">
    <Container>
      <Separator size="medium" />
      <Margins>
        <h3>LA EN PROFF GJØRE JOBBEN</h3>
        <b-row>
          <b-col sm="6" xl="8">
            <Margins>
              <p>
                Tenk om du hadde en helt egen gartner som sørget for at plenen
                alltid var perfekt klippet. Oppdag fordelene ved STIHL All
                Inclusive, tjenesten vår som gjør det både enkelt og lettvint å
                eie en iMOW® robotklipper.
              </p>
              <p>
                Alt er inkludert i en fast månedspris – installasjon, service og
                vinterlagring. Velg blant flere modeller og finn den som passer
                perfekt for deg og plenen din. Deretter regnes den faste
                månedsprisen ut, og betalingen deles opp på 60 måneder. Det er
                ingen ekstra rentekostnader, startavgifter eller restverdi i
                tillegg. Prisen du betaler for finansieringsperioden er prisen
                du ville betalt for robotgressklipper, installasjon,
                vinterservice og vinterlagring.
              </p>
              <p>Finansieringen gjøres i samarbeid med Resurs Bank.</p>
            </Margins>
          </b-col>

          <b-col cols="6" sm="3" xl="3">
            <img
              :data-src="require('@/assets/images/sai_lp_s1_imows.png')"
              alt="Stihl All Inclusive price"
              class="lazyload margins__mobile-sm"
            />
          </b-col>
        </b-row>
      </Margins>
    </Container>

    <Separator size="medium" />

    <div id="map">
      <SelectStore view />
    </div>
  </div>
</template>

<script>
import { Container, Margins, Separator } from '@/components';
import SelectStore from '@/views/SelectStore/SelectStore';
import { constants } from '@/mixins';
import { getDynamicTranslation } from '@/utils';
import { mapGetters } from 'vuex';
import { COOKIE_CONSENT_LEVELS } from '@/constants';

export default {
  name: 'MarketingView',
  mixins: [constants],
  components: {
    Container,
    Separator,
    Margins,
    SelectStore,
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1);
    }
  },
  methods: {
    scrollTo(hashtag) {
      setTimeout(() => {
        window.location.href = hashtag;
      }, 1);
    },
  },
  computed: {
    ...mapGetters(['hasConsentByLevel']),
    listItems() {
      return [
        'Kontakt din nærmeste <a href="https://stihlallinclusive.no/select-store">STIHL forhandler</a> og velg den modellen som passer best for deg.',
        'Forhandleren gjennomgår og installerer din nye iMOW® i hagen din. *',
        'Du betaler en fast månedspris. Service og vinterlagring i 4 år er inkludert.',
        'Etter 4 år kan du enten velge å fornye avtalen og få en ny iMOW®, eller du kan beholde den du har som din egen.',
      ];
    },
    returnChecklist() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_CHECKLIST_ITEMS'),
      ).map((r) => r);
    },
    returnItems() {
      return Object.values(
        getDynamicTranslation(this.$i18n, 'RETURN_ITEMS_ITEMS'),
      ).map((r) => r);
    },
    hasTargetingConsentLevel() {
      return this.hasConsentByLevel(COOKIE_CONSENT_LEVELS.TARGETING);
    },
  },
};
</script>
