<template>
  <div class="select-store-list">
    <Input
      v-model="zip"
      name="postalCode"
      :label="$t('MAP.ZIP_CODE')"
      :placeholder="$t('MAP.ZIP_CODE_PLACEHOLDER')"
      v-validate="{
        regex: REGEX.NO.postalCode,
      }"
      :error="errors.first('postalCode')"
      :data-vv-as="$t('MAP.VALIDATOR.POSTAL_CODE')"
      @input="onZipInput"
    />
    <transition name="fade-router" mode="out-in">
      <span v-if="geocoderError" class="input__error">
        {{ $t(`MAP.ERRORS.${geocoderError}`) }}
      </span>
    </transition>
    <ul
      :class="[
        'select-store-list__list',
        {
          'select-store-list__list--fade-top': !isScrolledToListTop,
          'select-store-list__list--fade-bottom': !isScrolledToListBottom,
        },
      ]"
      ref="list"
    >
      <li v-for="(store, index) in stores" :key="index">
        <Button
          variant="text"
          @mouseover.native="hoverStore(index)"
          @focus.native="hoverStore(index)"
          @click="chooseStore(store)"
          :active="selectedStore === index"
          :disabled="!isAvailable(store.id)"
        >
          <template v-slot:icon>
            <MapMarkerSVG class="icon icon--small text-grey" />
          </template>
          <div>
            <p>
              {{ store.name }}
            </p>
            <p v-if="store.distance && store.duration" class="text-strong">
              {{
                $t('MAP.APPROXIMATE_DISTANCE', [
                  store.distance.text,
                  store.duration,
                ])
              }}
            </p>
          </div>
          <span
            class="button__text-small text-small text-grey"
            v-if="!isAvailable(store.id)"
            >({{ $t('NOT_AVAILABLE') }})</span
          >
        </Button>
      </li>
    </ul>
  </div>
</template>

<script>
import { Button, Input } from '@/components';
import MapMarkerSVG from '@/assets/images/icon-pin.svg';
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import { constants } from '@/mixins';

export default {
  name: 'SelectStoreList',
  mixins: [constants],
  components: {
    Button,
    Input,
    MapMarkerSVG,
  },
  props: {
    selectedStore: Number,
    stores: Array,
  },
  data() {
    return {
      isScrolledToListTop: true,
      isScrolledToListBottom: false,
      zip: '',
    };
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100);
    setTimeout(() => this.handleScroll(), 1000);

    this.$refs.list.addEventListener('scroll', this.handleDebouncedScroll, {
      passive: true,
    });
  },
  beforeDestroy() {
    this.$refs.list.removeEventListener('scroll', this.handleDebouncedScroll, {
      passive: true,
    });
  },
  methods: {
    isAvailable() {
      return true;
    },
    sortByAvailable(storeA, storeB) {
      const aIsAvailable = this.isAvailable(storeA.id);
      const bIsAvailable = this.isAvailable(storeB.id);
      if (bIsAvailable > aIsAvailable) {
        return 1;
      } else if (bIsAvailable < aIsAvailable) {
        return -1;
      } else {
        return 0;
      }
    },
    handleScroll() {
      const element = this.$refs.list;

      this.isScrolledToListTop = element.scrollTop === 0;

      this.isScrolledToListBottom =
        element.offsetHeight + element.scrollTop === element.scrollHeight;
    },
    hoverStore(index) {
      this.$emit('hoverStore', index);
    },
    chooseStore(store) {
      this.$emit('chooseStore', store);
    },
    onZipInput: debounce(function (event) {
      if (!this.errors.items.length) this.$emit('zipSearch', event);
    }, 500),
  },
  computed: {
    ...mapState(['selectedBundle', 'geocoderError']),
  },
};
</script>

<style lang="scss">
.select-store-list {
  position: relative;
}

.select-store-list__list {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;

  @include min-width(md) {
    max-height: none;
    overflow: auto;
  }
}

.select-store-list__list--fade-top {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 3rem;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
    z-index: 1;

    @include min-width(md) {
      display: none;
    }
  }
}

.select-store-list__list--fade-bottom {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 3rem;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
    z-index: 1;

    @include min-width(md) {
      display: none;
    }
  }
}
.fade-router-enter-active,
.fade-router-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-router-enter,
.fade-router-leave-active {
  opacity: 0;
}
</style>
